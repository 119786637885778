import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Header.css';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header className="navbar">
      <nav>
        <h1>Ubercat.fr</h1>
        <button className="hamburger" onClick={toggleMenu}>
          ☰
        </button>
        <ul className={isOpen ? 'nav-links open' : 'nav-links'}>
          <li><Link to="/" onClick={toggleMenu}>Accueil</Link></li>
          <li><Link to="/annonces" onClick={toggleMenu}>Annonces</Link></li>
          <li><Link to="/galerie" onClick={toggleMenu}>Galerie</Link></li>
          <li><Link to="/educatif" onClick={toggleMenu}>Bonnes Pratiques</Link></li>
          <li><Link to="/live-video" onClick={toggleMenu}>Live Vidéo</Link></li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;